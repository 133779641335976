var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HEADER'),_c('MENU'),_c('div',{staticClass:"main"},[_c('div',[_c('h2',{staticClass:"title"},[_vm._v("エピソード登録/編集​")]),_c('el-form',{ref:"myForm",attrs:{"model":_vm.form}},[_c('table',{staticClass:"table table-bordered tablebox-t5 textarea-detile"},[_c('tbody',[_c('tr',[_c('th',{attrs:{"width":"160"}},[_c('span',{staticClass:"style_red"},[_vm._v("*")]),_vm._v("作品ID​")]),_c('td',[_c('el-form-item',{attrs:{"label":"","prop":"nameRef"}},[_c('el-input',{staticClass:"px-4 py-3",attrs:{"type":"text","placeholder":"XXXXXXXX","disabled":""},model:{value:(_vm.form.id),callback:function ($$v) {_vm.$set(_vm.form, "id", $$v)},expression:"form.id"}})],1)],1)]),_c('tr',[_c('th',{attrs:{"width":"160"}},[_c('span',{staticClass:"style_red"},[_vm._v("*")]),_vm._v("作品タイトル ")]),_c('td',[_c('el-form-item',{attrs:{"label":""}},[_c('el-input',{staticClass:"px-4 py-3",attrs:{"type":"text","placeholder":"作品タイトルを入力して下さい。​","disabled":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)]),_c('tr',[_c('th',{attrs:{"width":"160"}},[_c('span',{staticClass:"style_red"},[_vm._v("*")]),_vm._v("ストーリー​")]),_c('td',[_c('div',{staticClass:"text-center px-4 py-3"},[_c('router-link',{attrs:{"to":`/drama/${_vm.id}/episode/store`}},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("エピソード登録​")])],1),_c('el-table',{staticClass:"table table-bordered tablebox",staticStyle:{"font-size":"1.6rem"},attrs:{"header-cell-style":{
                      background: '#efefef',
                      color: '#212529',
                      fontWeight: '500',
                    },"data":_vm.listEpisodes,"cell-style":{ fontSize: '16px' }}},[_c('el-table-column',{attrs:{"header-align":"center","align":"center","label":"作品ID​","width":"150","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":`/drama/${_vm.id}/episode/${scope.row.id}`}},[_vm._v(" "+_vm._s(scope.row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"header-align":"center","align":"center","label":"エピソードNo.​","width":"auto","prop":"order_no"}}),_c('el-table-column',{attrs:{"header-align":"start","align":"center","label":"エピソードタイトル​​","width":"auto","prop":"title"}}),_c('el-table-column',{attrs:{"header-align":"center","align":"center","label":"時間​​","width":"auto","prop":"time"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.convertSecondsToHHMMSS(scope.row.time))+" ")]}}])}),_c('el-table-column',{attrs:{"header-align":"center","align":"center","label":"ポイント設定​","width":"auto","prop":"points"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.points === 0)?_c('div',{staticClass:"label-free"},[_vm._v(" 無料 ")]):_c('div',[_vm._v(" "+_vm._s(scope.row.points)+" ")])]}}])}),_c('el-table-column',{attrs:{"header-align":"center","align":"center","label":"ポイント消費数","width":"auto","prop":"purchases_sum_points"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.purchases_sum_points ? scope.row.purchases_sum_points : 0)+" ")]}}])})],1)],1)])])])])]),_c('div',{staticClass:"btnbox text-center mt50 mb100"},[_c('router-link',{attrs:{"to":{
            name: 'drama-update',
            params: { id: _vm.id },
          }}},[_c('button',{staticClass:"btn btn-red ml20"},[_vm._v("更新する​")])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }